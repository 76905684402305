<template>
  <div class="wrap">
    <div class="bgimg">
      <img src="../../../assets/imgs/APPdownload.png" class="image" />
    </div>
    <div class="box">
      <div><img src="../../../assets/imgs/starticon.png" width="70vw" /></div>
      <div class="title">GOWOW APP</div>
      <div class="content">
        <p>{{ $t("slides.下载GOWOW APP") }}</p>
        <p>{{ $t("slides.打造专属于你的猎户座") }}</p>
      </div>
      <a
        class="apple"
        href="https://apps.apple.com/us/app/gowow-app/id6443576078"
        target="_blank"
      >
        <img src="../../../assets/imgs/iphone.png" />
        {{ $t("slides.APPStore下载") }}
      </a>
    </div>
  </div>
</template>
    
    <script>
export default {
  methods: {},
};
</script>
    
<style lang='scss' scoped>
img {
  vertical-align: top;
}
.wrap {
  position: relative;
  width: 100vw;
}
.box {
  position: absolute;
  top: 30%;
  left: 10%;
  width: 100%;

  .title {
    font-family: PingFang-SC-Heavy;
    font-weight: 600;
    font-size: 38px;
    line-height: 70px;
  }
  .content {
    font-family: PingFang-SC-Medium;
    // font-weight: 200;
    font-size: 16px;
    line-height: 25px;
  }
  .apple {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    width: max-content;
    height: 60px;
    margin-top: 3vh;
    border: black 1px solid;
    border-radius: 5px;
    font-family: PingFang-SC-Bold;
    font-weight: 600;
    font-size: 20px;
    padding-right: 7px;
    color: black;
    img {
      width: 50px;
    }
  }
}
.bgimg {
  width: 100vw;
  background-color: rgb(230, 223, 214);
  .image {
    width: 100vw;
  }
}
</style>