<template>
  <div>
    <div class="wrap" v-if="platform.isPC">
      <div class="head" v-if="false">
        <img class="logo" src="@assets/imgs/logo.png" alt="gowow" />
        <span class="icon_add">+</span>
      </div>
      <div class="list">
        <div class="item">
          <div class="title">{{ $t("index.产品") }}</div>
          <div class="content">
            <div class="content-item clickable" @click="goto('/product/ORI')">
              {{ $t("index.猎户座") }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="title">GOWOW</div>
          <div class="content">
            <div class="content-item">
              <div
                class="content-item clickable"
                @click="goto('/brand/concept')"
              >
                {{ $t("index.品牌理念") }}
              </div>
            </div>
            <div class="content-item" v-if="false">
              {{ $t("index.概念产品预告") }}
            </div>
            <div class="content-item">
              <div class="content-item clickable" @click="goto('/brand/news')">
                {{ $t("index.品牌大事件") }}
              </div>
            </div>
            <div class="content-item">
              <div class="content-item clickable" @click="goto('/brand/class')">
                {{ $t("index.品牌课堂") }}
              </div>
            </div>
          </div>
        </div>
        <div class="item" v-if="false">
          <div class="title">{{ $t("index.使用说明") }}</div>
          <div class="content">
            <div class="content-item">{{ $t("index.产品完整说明书") }}</div>
            <div class="content-item">{{ $t("index.使用手册") }}</div>
            <div class="content-item">FAQ</div>
          </div>
        </div>
        <div class="item" v-if="false">
          <div class="title">{{ $t("index.帮助") }}</div>
          <div class="content">
            <div class="content-item">
              {{ $t("index.隐私政策 & Cookie政策") }}
            </div>
            <div class="content-item">{{ $t("index.法律问题") }}</div>
          </div>
        </div>
        <div class="item" v-if="false">
          <div class="title">
            {{ $t("index.社区") }}
          </div>
          <div class="content">
            <div
              class="content-item clickable"
              @click="goto('/motocircle/list')"
            >
              {{ $t("lifeStyle.摩友圈") }}
            </div>
          </div>
        </div>
        <div class="item">
          <div class="title">{{ $t("index.联系我们") }}</div>
          <div class="content">
            <div class="content-item" v-if="isCN">
              {{ $t("index.联系我们") }}：010-63724080
            </div>
            <div class="content-item" v-if="!isCN">
              {{ $t("index.联系我们") }}：+86-10-63724080
            </div>
            <div class="content-item">
              {{ $t("index.发送邮件") }}：service@gowow.cn
            </div>
          </div>
        </div>
        <div class="item">
          <div class="title">{{ $t("index.关注我们") }}</div>
          <div class="content">
            <div class="content-item" v-if="isCN">
              {{ $t("index.微博") }}：@Gowow格物官方微博
            </div>
            <div class="content-item" v-if="isCN">
              {{ $t("index.微信") }}：@格物Gowow
            </div>
            <div class="content-item" v-if="!isCN">
              Instagram：@gowowofficial
            </div>
            <div class="content-item" v-if="!isCN">
              Facebook：@Gowow Dirt Ebike
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="divide"></div> -->
      <div class="copyright">
        <p v-if="isCN">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >京ICP备2023020355号-1</a
          >
        </p>
        &nbsp;|&nbsp;
        <div>Copyright © 2022 Gowow</div>
        &nbsp;|&nbsp;
        <div>All rights reserved. Terms &amp; conditions</div>
      </div>
    </div>
    <div class="mwrap" v-else>
      <div class="esc" @click="esctop">
        <span>{{ $t("index.回到顶部") }}</span
        ><i class="el-icon-arrow-up"></i>
      </div>
      <div :class="['nav-item']">
        <div class="nav-item-content" @click="onNavClick(0, 0)">
          <span>{{ $t("index.猎户座") }}</span>
        </div>
      </div>
      <div :class="['nav-item']">
        <div class="nav-item-content" @click="onNavClick(1)">
          <span> {{ $t("index.vr看车") }}</span>
        </div>
      </div>
      <div :class="['nav-item']">
        <div class="nav-item-content" @click="gopartner">
          <span> {{ $t("partner.合作伙伴") }}</span>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 7 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(7)">
          <span>{{ $t("index.关于我们") }}</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '7_1' }]"
            @click="onNavClick(7, 1)"
          >
            {{ $t("lifeStyle.格物电动摩托车") }}
          </div>
        </div>
      </div>
      <div :class="['nav-item', { active: spreadNavIdx === 3 }]">
        <div class="nav-item-content" @click="setSpreadNavIdx(3)">
          <span>{{ $t("index.品牌") }}</span>
          <i></i>
        </div>
        <div class="sub-nav">
          <div
            :class="['sub-nav-item', { active: curNav === '3_1' }]"
            @click="onNavClick(3, 1)"
          >
            {{ $t("index.品牌理念") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '3_2' }]"
            @click="onNavClick(3, 2)"
          >
            {{ $t("index.品牌大事件") }}
          </div>
          <div
            :class="['sub-nav-item', { active: curNav === '3_3' }]"
            @click="onNavClick(3, 3)"
          >
            {{ $t("index.品牌课堂") }}
          </div>
        </div>
      </div>
      <div class="popover">
        <div v-if="!isCN">
          <el-popover
            placement="top"
            width="auto"
            v-model="visible1"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@Gowow Dirt Ebike</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/facebook-fill.png"
              @click="visible1 = !visible1"
              slot="reference"
            />
          </el-popover>
        </div>
        <div v-if="!isCN">
          <el-popover
            placement="top"
            width="auto"
            v-model="visible2"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@GOWOWMOTO</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/instagram-fill.png"
              @click="visible2 = !visible2"
              slot="reference"
            />
          </el-popover>
        </div>
        <div v-if="isCN">
          <el-popover
            placement="top"
            width="auto"
            v-model="visible5"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@格物Gowow</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/wechat-fill.png"
              @click="visible5 = !visible5"
              slot="reference"
            />
          </el-popover>
        </div>
        <div v-if="isCN">
          <el-popover
            placement="top"
            width="auto"
            v-model="visible6"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">@Gowow格物官方微博</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/weibo-circle-fill.png"
              @click="visible6 = !visible6"
              slot="reference"
            />
          </el-popover>
        </div>

        <div>
          <el-popover
            placement="top"
            width="auto"
            v-model="visible3"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">Service@gowow.cn</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/mail-fill.png"
              @click="visible3 = !visible3"
              slot="reference"
            />
          </el-popover>
        </div>
        <div>
          <el-popover
            placement="top"
            width="auto"
            v-model="visible4"
            trigger="manual"
          >
            <p style="font-size: 140%; line-height: 200%">+86-10-63724080</p>
            <div style="text-align: center; margin: 0"></div>
            <img
              src="@assets/imgs/footerM-icon/phone-fill.png"
              @click="visible4 = !visible4"
              slot="reference"
            />
          </el-popover>
        </div>
      </div>
      <div class="copyrightm">
        <p v-if="isCN">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >京ICP备2023020355号-1</a
          >
        </p>
        <p>Copyright © 2022 Gowow</p>
        <p>All rights reserved. Terms &amp; conditions</p>
      </div>
    </div>
  </div>
</template>

<script>
import { goto } from "@utils/goto";
import { mapState } from "vuex";
import platform from "@utils/platform";
import RouteMap, { activeRoute } from "./Header/RouteMap";

export default {
  computed: {
    ...mapState(["lang"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  data() {
    return {
      platform,
      curNav: activeRoute,
      spreadNavIdx: 0,
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: false,
    };
  },
  methods: {
    goto,
    setSpreadNavIdx(idx) {
      //  2暂时没有菜单
      if ([2].includes(idx)) return;
      if (idx === this.spreadNavIdx) {
        this.spreadNavIdx = "";
      } else {
        this.spreadNavIdx = idx;
      }
    },
    onNavClick(navIdx, nav2Idx = 0) {
      this.curNav = `${navIdx}_${nav2Idx}`;
      const url = RouteMap[this.curNav];
      if (url) {
        goto(url);
      }
    },
    gopartner() {
      goto("/partner");
    },
    esctop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";

.clickable {
  cursor: pointer;
}

.mwrap {
  font-size: 0;
  width: 100vw;
  height: auto;
  padding: 0 px2rem(20px) px2rem(20px) px2rem(20px);
  background-color: black;
  color: white;
  .esc {
    width: 100%;
    text-align: center;
    font-size: px2rem(16px);
    font-family: PingFang SC;
    font-weight: 400;
    line-height: px2rem(40px);
    border-bottom: px2rem(1px) solid #9f9f9f;
  }

  .copyrightm {
    padding-top: px2rem(10px);
    font-family: HelveticaNeueLTPro-Roman;
    font-size: px2rem(12px);
    line-height: px2rem(18px);
    text-transform: uppercase;
    border: none;
    a {
      color: white;
    }
  }
  .popover {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: px2rem(20px) 0 px2rem(5px);
    div {
      width: 15%;

      img {
        width: 100%;
      }
    }
  }
  .nav-item {
    // margin-top: px2rem(5px);
    // line-height: px2rem(70px);
    width: 100%;
    // 展开
    &.active {
      height: auto;
      .nav-item-content i {
        background-image: url(@assets/imgs/icon_remove_white.png);
      }
      .sub-nav {
        display: block;
        border: none;
      }
    }
    .nav-item-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: px2rem(17px);
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      line-height: px2rem(40px);
      height: px2rem(50px);
      // padding-bottom: px2rem(3px);
      border-bottom: px2rem(1px) solid #9f9f9f;
      i {
        width: px2rem(25px);
        height: px2rem(25px);
        background: url(@assets/imgs/icon_add_white.png) no-repeat center;
        background-size: 60%;
      }
    }
    .sub-nav {
      display: none;
      // padding-bottom: px2rem(20px);
      font-size: px2rem(14px);
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      // line-height: px2rem(20px);
      .sub-nav-item {
        font-size: px2rem(16px);
        font-family: PingFang-SC-Bold, PingFang-SC;
        line-height: px2rem(30px);
        margin-top: px2rem(8px);
        // height: px2rem(50px);
        &.active {
          color: #e8e354;
        }
        &:nth-child(n + 2) {
          font-weight: 400;
        }
      }
    }
  }
}

.wrap {
  // box-shadow: 0px 0px 12px 0px rgba(215, 215, 215, 0.5);
  // display: flex;
  // justify-content: space-between;
  position: relative;
  z-index: 2;

  .head {
    display: none;
  }

  .list {
    margin-top: 15px;
    margin-left: 73px;
    flex: 1;
    display: flex;
    // justify-content: space-around;

    .item {
      white-space: nowrap;
      // width: 180px;
      // margin: 0 50px;

      &:not(:first-child):not(:last-child) {
        margin: 0 50px;
      }

      .title {
        font-weight: 550;
        font-size: 16px;
        line-height: 20px;
        // height: 27px;
        font-family: PingFangSC-Regular;
        color: rgba(0, 0, 0, 1);
      }

      .content {
        margin-top: 4px;
        font-family: PingFangSC-Thin;
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  .divide {
    flex-basis: 182px;
  }

  .copyright {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    // height: 44px;
    font-family: HelveticaNeueLTPro-Roman;
    font-size: 12px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.61);
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
    a {
      color: rgba(0, 0, 0, 0.61);
      &:hover {
        color: orange;
      }
    }
  }
}

// m端
// @media (max-width: $screen-md-min) {
//   .wrap {
//     padding: 0 px2rem(15px);

//     .head {
//       margin-top: px2rem(22px);
//       display: flex;
//       justify-content: space-between;
//       align-items: center;

//       .logo {
//         height: px2rem(25px);
//       }

//       .icon_add {
//         color: #000000;
//         font-size: px2rem(24px);
//         font-weight: bold;
//       }
//     }

//     .list {
//       margin-top: px2rem(31px);

//       .item {
//         margin-top: px2rem(10px);
//         white-space: nowrap;

//         .title {
//           font-size: px2rem(17px);
//           line-height: px2rem(24px);
//           height: px2rem(27px);
//           font-family: PingFangSC-Bold;
//           color: rgba(0, 0, 0, 1);
//         }

//         .content {
//           margin-top: px2rem(4px);
//           font-family: PingFangSC-Thin;
//           font-size: px2rem(14px);
//           line-height: px2rem(20px);
//           color: rgba(0, 0, 0, 0.61);
//         }
//       }
//     }

//     .divide {
//       margin: px2rem(16px) 0;
//       height: 2px;
//       background: #000000;
//     }

//     .copyright {
//       margin-bottom: px2rem(23px);
//       font-family: HelveticaNeueLTPro-Roman;
//       font-size: px2rem(12px);
//       line-height: px2rem(18px);
//       color: rgba(0, 0, 0, 1);
//       text-transform: uppercase;
//     }
//   }
// }
</style>
