<template>
  <div class="wrap">
    <div class="box">
      <div><img src="../../../assets/imgs/starticon.png" /></div>
      <div class="title">GOWOW APP</div>
      <div class="content">
        <p>{{ $t("slides.下载GOWOW APP") }}</p>
        <p>{{ $t("slides.打造专属于你的猎户座") }}</p>
      </div>
      <a
        class="apple"
        href="https://apps.apple.com/us/app/gowow-app/id6443576078"
        target="_blank"
      >
        <img src="../../../assets/imgs/iphone.png" />{{
          $t("slides.APPStore下载")
        }}
      </a>
    </div>
    <div class="bgimg">
      <img src="../../../assets/imgs/homeM/APP.png" />
    </div>
  </div>
</template>
      
      <script>
export default {
  methods: {},
};
</script>
      
 <style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
@import "~@assets/styles/func.scss";
* {
  padding: 0;
  margin: 0;
}
img {
  vertical-align: top;
}
.wrap {
  position: relative;
}
.box {
  width: 100vw;
  text-align: center;
  padding: px2rem(10px) 0;
  position: absolute;
  top: 0;
  img {
    width: px2rem(70px);
  }
  .title {
    font-family: PingFang-SC-Heavy;
    font-weight: 600;
    font-size: px2rem(34px);
    line-height: px2rem(50px);
  }
  .content {
    font-family: PingFang-SC-Medium;
    font-size: px2rem(16px);
    margin: px2rem(10px) 0;
    line-height: px2rem(24px);
  }
  .apple {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background-color: #fff;
    height: px2rem(60px);
    width: max-content;
    border: black 1px solid;
    border-radius: px2rem(5px);
    font-family: PingFang-SC-Bold;
    font-weight: 600;
    font-size: px2rem(18px);
    color: black;
    padding-right: px2rem(7px);
    img {
      width: px2rem(50px);
    }
  }
}
.bgimg {
  background-color: rgb(244, 244, 244);

  img {
    width: 100%;
  }
}
</style>