<template>
  <div class="wrap">
    <img src="../../../assets/imgs/trial.png" />
    <div class="box">
      <p class="title"><b>ORI</b> {{ $t("index.预约试驾") }}</p>
      <van-form
        class="textbox"
        ref="form"
        @submit="onSubmit"
        label-width="auto"
        colon
        :show-error="false"
      >
        <van-field
          class="text"
          v-model="address"
          name="address"
          :label="$t('index.体验地点')"
          required
          :rules="[{ required: true, message: $t('index.请填写体验地点') }]"
        />
        <div class="phonebox">
          <i class="el-icon-plus" v-if="!isCN"></i>
          <van-field
            v-if="!isCN"
            v-model="countrycode"
            class="text countrycode"
            type="tel"
            name="countrycode"
            :label="$t('register.请输入国家编码')"
            required
            :rules="[
              { required: true, message: $t('register.请输入国家编码') },
            ]"
          />
          <span v-if="!isCN">&nbsp;&nbsp;</span>
          <van-field
            class="text"
            v-model="phone"
            type="tel"
            name="phone"
            :label="$t('index.手机号')"
            required
            :rules="[
              { required: true, message: $t('index.请填写手机号') },
              { message: $t('index.格式不正确'), validator: validatorTel },
            ]"
          />
        </div>
        <div class="verifybox">
          <van-field
            class="verifyinput"
            v-model="verify"
            name="verify"
            :label="$t('register.验证码')"
            required
            :rules="[{ required: true, message: $t('register.请输入验证码') }]"
          />&nbsp;&nbsp;
          <van-button
            block
            class="verifybtn"
            type="info"
            @click="onCodeBtnClick"
            :disabled="disabled"
          >
            {{ disabled ? `${count}s` : $t("register.获取验证码") }}
          </van-button>
        </div>
        <div class="submit-btn-wrap">
          <van-button block type="info" native-type="submit" color="black">
            {{ $t("index.提交") }}
          </van-button>
        </div>
      </van-form>
    </div>
    <ul class="content">
      <li>
        {{
          $t(
            "index.产品体验官会根据当前咨询人数情况，尽快与您致电并提供购买新能源摩托车咨询服务"
          )
        }}
      </li>
      <li v-if="false">{{ $t("index.购车咨询点击转400") }}</li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Message from "@utils/Message";
import { feedbackAdd2, verificationCode } from "@service/api";
// import { log } from "console";

export default {
  data() {
    return {
      // 表单业务数据
      phone: "",
      verify: "",
      address: "",
      countrycode: "",
      disabled: false,
      count: 60,
      timeRef: null,
    };
  },
  computed: {
    ...mapState(["lang"]),
    isCN() {
      return this.lang === "zh-CN";
    },
  },
  async mounted() {
    // console.log(this.isCN);
  },
  watch: {},
  beforeDestroy() {
    this.timeRef && clearInterval(this.timeRef);
  },
  methods: {
    onCodeBtnClick() {
      if (!this.disabled) {
        const filed = "phonenumber";
        const value = this.phone;
        // console.log(value);
        if (!value) {
          const msg = "请输入电话";
          Message.fail(this.$t(`register.${msg}`));
          return;
        }
        const params = { [filed]: value };
        if (!this.isCN) {
          params.phonenumber = "+" + this.countrycode + this.phone;
        }
        console.log(params);
        this.verificationCodeAsync(params);
      }
      this.codeCount();
    },
    // 发送验证码
    async verificationCodeAsync(params) {
      try {
        await verificationCode(params);
        Message.success(this.$t("index.验证码发送成功"));
      } catch (e) {
        if (e == "一小时内验证码发送太频繁") {
          Message.fail(this.$t("index.一小时内验证码发送太频繁"));
        } else if (e == "今日验证码发送太频繁") {
          Message.fail(this.$t("index.今日验证码发送太频繁"));
        } else if (e == "验证码发送太频繁") {
          Message.fail(this.$t("index.验证码发送太频繁"));
        } else {
          Message.fail(this.$t("index.验证码发送失败"));
        }
      }
    },
    codeCount() {
      this.disabled = true;
      this.count -= 1;
      this.timeRef = setInterval(() => {
        this.count -= 1;
        if (this.count < 1) {
          this.disabled = false;
          this.count = 60;
        }
      }, 1000);
    },
    async onSubmit(values) {
      const { phone, verify, address = "" } = values;
      // area.split('/')
      // _address = `${address} ${area.replace(/\//g, " ")}`;
      const params = {
        phone,
        code: verify,
        address,
      };
      try {
        await feedbackAdd2(params);
        Message.success(this.$t("index.提交成功"));
      } catch (e) {
        if (e == "验证码不正确或已失效") {
          Message.fail(this.$t("index.验证码不正确或已失效"));
        } else if (e == "请求已提交过") {
          Message.fail(this.$t("index.您已提交过申请"));
        } else {
          Message.fail(this.$t("index.提交失败"));
        }
      }
      this.reset();
    },
    reset() {
      this.phone = "";
      this.verify = "";
      this.address = "";
      this.$refs.form.resetValidation();
    },
    validatorTel(value) {
      const phoneReg = /^1[3456789][0-9]{9}$/;
      if (this.isCN) return phoneReg.test(value);
      // 非中文不验证电话
      return true;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";

// pc端
@media (min-width: $screen-md-min) {
  .submit-btn-wrap {
    margin: 26px auto;
    width: 200px;
  }

  img {
    width: 100%;
    height: 100%;
    position: relative;
    vertical-align: top;
  }

  ::v-deep .van-cell__title {
    min-width: 60px;
  }
  ::v-deep .van-cell {
    font-size: 12px;
  }
  ::v-deep .van-field__error-message {
    position: absolute;
    left: -80px;
  }

  .wrap {
    position: relative;
    background-color: rgb(230, 223, 214);

    .content {
      position: absolute;
      bottom: 15%;
      color: white;
      font-family: PingFang-SC-Medium;
      font-size: 14px;
      padding-left: 100px;
    }
  }
  .box {
    position: absolute;
    top: 0;
    width: 100vw;
    padding-top: 100px;
    padding-left: 160px;
    .text {
      border-radius: 5px;
      margin: 20px 0;
      height: 40px;
    }
    .textbox {
      width: 500px;
    }
    .title {
      font-family: PingFang-SC-Regular;
      font-size: 40px;
      line-height: 70px;
    }
    .phonebox {
      display: flex;
      align-items: center;
      font-size: 12px;
      .countrycode {
        width: 350px;
        ::v-deep .van-field__error-message {
          position: absolute;
          left: -100px;
        }
      }
    }
    .verifybox {
      display: flex;
      margin: 20px 0;
      .verifyinput {
        border-radius: 5px;
        height: 40px;
      }
      .verifybtn {
        width: 45%;
        background-color: rgba($color: #000000, $alpha: 0);
        border: none;
        border-bottom: #000000 1px solid;
        border-radius: 0px;
        color: #000;
        padding-top: 15px;
        font-size: 12px;
        position: relative;
        top: -5px;
      }
    }
  }
}

// m端
@media (max-width: $screen-md-min) {
  .submit-btn-wrap {
    margin: 16px auto;
  }
}
</style>
