<template>
  <div class="area-wrap">
    <el-select
      v-model="select.country.id"
      @change="onChangeCountry"
      class="area-select"
      filterable
    >
      <el-option
        v-for="item in option_country"
        :key="`${item.id}_${item.name}`"
        :value="item.id"
        :label="item.name"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="select.state.id"
      @change="onChangeState"
      class="area-select"
      filterable
    >
      <el-option
        v-for="item in option_state"
        :key="`${item.id}_${item.name}`"
        :value="item.id"
        :label="item.name"
      >
      </el-option>
    </el-select>
    <el-select
      v-model="select.city.id"
      @change="onChangeCity"
      class="area-select"
      filterable
    >
      <el-option
        v-for="item in option_city"
        :key="`${item.id}_${item.name}`"
        :value="item.id"
        :label="item.name"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
import { getCountries, getStates, getCities } from "@service/api";
import { isCN, lang } from "@utils/lang";
export default {
  model: {
    prop: "area",
    event: "onAreaChange",
  },
  props: ["area"],
  data() {
    return {
      lang,
      option_country: [],
      option_state: [],
      option_city: [],
      select: {
        country: {},
        state: {},
        city: {},
      },
    };
  },
  async mounted() {},
  watch: {
    "lang.value": function (val) {
      if (val) {
        this.getCountries();
      }
    },
    area: async function (val = []) {
      const [
        { id: countryId } = {},
        { id: stateId } = {},
        { id: cityId } = {},
      ] = val;

      if (!countryId) return;
      if (countryId !== this.select.country.id) {
        await this.getCountries(countryId);
      }
      if (!stateId) return;
      if (stateId !== this.select.state.id) {
        await this.getStates(countryId, stateId);
      }
      if (!cityId) return;
      if (cityId !== this.select.city.id) {
        await this.getCities(stateId, cityId);
      }
    },
  },
  methods: {
    onChangeCountry(countryId) {
      const country =
        this.option_country.find((item) => item.id === countryId) || {};
      this.select.country = country;
      this.option_state = [];
      this.select.state = {};
      this.option_city = [];
      this.select.city = {};
      this.getStates(countryId);
      this.onAreaChange();
    },
    onChangeState(stateId) {
      const state = this.option_state.find((item) => item.id === stateId) || {};
      this.select.state = state;
      this.option_city = [];
      this.select.city = {};
      this.getCities(stateId);
      this.onAreaChange();
    },
    onChangeCity(cityId) {
      const city = this.option_city.find((item) => item.id === cityId) || {};
      this.select.city = city;
      this.onAreaChange();
    },
    async getCountries(countryId) {
      try {
        const data = (await getCountries()) || [];
        this.option_country = data;
        if (countryId !== undefined) {
          const country =
            this.option_country.find((item) => item.id === countryId) || {};
          this.select.country = country;
        }
        if (!this.select.country.id && isCN()) {
          // 默认
          const chana = data.find((item) => item.id === 45);
          this.select.country = chana;
          this.onChangeCountry(chana.id);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getStates(countryId, stateId) {
      try {
        const data = await getStates({ countryId });
        this.option_state = data;
        if (stateId !== undefined) {
          const state =
            this.option_state.find((item) => item.id === stateId) || {};
          this.select.state = state;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getCities(stateId, cityId) {
      try {
        const { id: countryId } = this.select.country;
        const data = await getCities({ countryId, stateId });
        this.option_city = data;
        if (cityId !== undefined) {
          const city =
            this.option_city.find((item) => item.id === cityId) || {};
          this.select.city = city;
        }
      } catch (e) {
        console.log(e);
      }
    },
    onAreaChange() {
      const arr = [this.select.country, this.select.state, this.select.city];
      const nameArr = arr.map((item = {}) => item.name).filter((item) => item);
      let areaName = "";
      if (isCN()) {
        areaName = nameArr.join(" ");
      } else {
        areaName = [...nameArr].reverse().join(" ");
      }
      this.$emit("onAreaNameChange", areaName);
      this.$emit("onAreaChange", arr);
    },
  },
};
</script>

<style lang="scss" scoped>
.area-wrap {
  display: flex;
  justify-content: space-between;
  .area-select {
    width: 350px;
    margin-right: 10px;
  }
}
</style>