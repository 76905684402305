const RouteMap = {};
RouteMap['0_0'] = '/product/ORI';
RouteMap['1_0'] = '/vrlook/ORI';
RouteMap['6_1'] = '/motocircle/list';
RouteMap['3_1'] = '/brand/concept';
RouteMap['3_2'] = '/brand/news';
RouteMap['3_3'] = '/brand/class';
RouteMap['4_0'] = '/personal';
RouteMap['7_1'] = '/aboutus/electricdirtbike';
// RouteMap['7_2'] = '/partner';

const pathname = location.pathname;
const url = location.href;
const urlMaps = {
  '/vrlook/ORI': '1_0',
  '/motocircle/list': '6_1',
  '/motocircle/detail': '6_1',
  '/motocircle/post': '6_1',
  '/product/ORI': '0_0',
  '/brand/class': '3_3',
  '/brand/concept': '3_1',
  '/brand/news': '3_2',
  '/personal': '4_0',
  '/aboutus/electricdirtbike': '7_1',
  // '/partner': '7_2',

};
const matchUrl = () => {
  for (let k in urlMaps) {
    if (url.indexOf(k) > -1) {
      return urlMaps[k];
    }
  }
};
export const activeRoute = matchUrl() || RouteMap[pathname];

export default RouteMap;
